<template>
  <div>
    <v-container v-if="userData.role === 'CUST'" fluid class="px-3 px-lg-0">
      <v-row>
        <v-col cols="12" md="4" lg="3">
          <v-row>
            <v-col sm="6" md="12">
              <v-card class="pa-5 pb-6 pa-md-0 pb-md-0" rounded="lg">
                <div v-if="!$vuetify.breakpoint.smAndDown" class="white text-center">
                  <img src="@/assets/images/logos/hatko-logo.png" width="250" height="120" class="pa-9" />
                </div>

                <v-list two-line>
                  <v-subheader> {{ $t('firmaBilgileriniz') }}</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiDomain }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{ $t('cariUnvan') }}</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.company }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiCodeBrackets }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{ $t('musteriNumarasi') }}</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.cari_kod }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-3" />
                  <v-subheader> {{ $t('satisTemsilciniz') }}</v-subheader>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiAccount }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{ $t('adiSoyadi') }}</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider inset></v-divider>
                  <v-list-item>
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiPhone }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{ $t('cepTelefonu') }}</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_phone }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider inset></v-divider>

                  <v-list-item class="pb-5">
                    <v-list-item-avatar color="nightDark">
                      <v-avatar>
                        <v-icon :size="24">
                          {{ icons.mdiAt }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-subtitle>{{ $t('ePosta') }}</v-list-item-subtitle>
                      <v-list-item-title>{{ userData.saldept_email }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8" lg="9">
          <v-row>
            <v-col cols="12" lg="7">
              <v-row style="height: calc(100% + 24px)">
                <v-col cols="12" sm="6">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiPodium }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">{{ $t('buAykiToplam') }}</div>
                        <div class="text-body-1 font-weight-medium pb-5">{{ $t('siparisTutari') }}</div>
                        <v-chip outlined color="primary" label>
                          {{ toplamSiparisTutari | currency }}
                        </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card color="grey" rounded="lg" height="100%" class="d-flex align-center py-3">
                    <div class="d-flex">
                      <v-avatar class="mx-5" color="secondary">
                        <v-icon color="white" size="24">
                          {{ icons.mdiCartArrowDown }}
                        </v-icon>
                      </v-avatar>
                      <div>
                        <div class="text-caption font-weight-light">{{ $t('buAykiToplam') }}</div>
                        <div class="text-body-1 font-weight-medium pb-5">{{ $t('siparisAdedi') }}</div>
                        <v-chip outlined color="primary" label> {{ thisMonthOrderCount }} </v-chip>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" lg="5">
              <v-card rounded="lg" height="100%">
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="text-body-2 py-2">{{ $t('toplamSiparisTutari') }}</td>
                        <td class="text-body-2 py-2">
                          <span class="warning--text"> : {{ balance.BORC | currency }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-body-2 py-2">{{ $t('odenenTutar') }}</td>
                        <td class="text-body-2 py-2">
                          <span class="success--text"> : {{ balance.ALACAK | currency }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-body-2 py-2">{{ $t('odenecekTutar') }}</td>
                        <td class="text-body-2 py-2">
                          <span class="error--text"> : {{ balance.BAKIYE | currency }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  <v-avatar color="secondary">
                    <v-icon :size="24" color="white">
                      {{ icons.mdiStar }}
                    </v-icon>
                  </v-avatar>
                  <span class="ml-5 primary--text">
                    <div class="text-caption">{{ $t('son1Yil') }}</div>
                    <div class="text-body-1 font-weight-medium">{{ $t('enCokSatinAldiginizUrunler') }}</div>
                  </span>
                  <v-spacer />
                  <v-btn v-if="$vuetify.breakpoint.smAndUp" color="secondary" depressed small to="AcikSiparisler">
                    {{ $t('tumSiparisleriniz') }}
                  </v-btn>
                </v-card-title>

                <v-data-table :headers="headers" :items="toSipAdetiRec" :items-per-page="10" hide-default-footer>
                  <template #[`item.CUSTORDERNUM`]="{ item }">
                    <span> {{ item.CUSTORDERNUM }}</span>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="8">
          <span class="text-caption">2023 © Hatko Electronics</span>
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-chip small label> v1.0.1 </v-chip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useRouter } from '@core/utils'
import { getCurrentInstance, onMounted, ref } from '@vue/composition-api'

import {
  mdiAccount,
  mdiAt,
  mdiCalendar,
  mdiCalendarClock,
  mdiCartArrowDown,
  mdiChevronLeft,
  mdiCodeBrackets,
  mdiDomain,
  mdiFilter,
  mdiIp,
  mdiLogin,
  mdiPhone,
  mdiPodium,
  mdiSend,
  mdiStar,
  mdiTrayFull,
} from '@mdi/js'

import { initialAbility } from '@/plugins/acl/config'
import i18n from '@/plugins/i18n'
import store from '@/store'
import VueCurrencyFilter from 'vue-currency-filter'

export default {
  setup() {
    // UnRegister on leave

    const userData = JSON.parse(localStorage.getItem('userData'))

    const vm = getCurrentInstance()?.proxy
    const { router } = useRouter()

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')
      localStorage.removeItem('currency')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    const toSipAdetiRec = ref([])
    const headers = ref([
      {
        text: i18n.t('urunKodu'),
        align: 'start',
        sortable: false,
        value: 'prd_code',
        width: '150',
      },
      { text: i18n.t('urunAdi'), value: 'baslik', sortable: false },
      {
        text: i18n.t('siparisAdet'),
        value: 'topSipAdeti',
        align: 'right',
        sortable: false,
        width: '150',
      },
    ])
    const toplamSiparisTutari = ref(0)
    const thisMonthOrderCount = ref(0)

    const currenci = ref('')
    const sonuc = ref({ create_date: '', last_login: '' })
    const hasLoaded = ref(false)

    onMounted(() => {
      if (hasLoaded.value == false) {
        fetchDashboard()

        // Set the flag to true after the page is loaded
      }
    })
    const balance = ref({})
    const fetchDashboard = () => {
      const method = 'getCustomerDashboardVars'

      store.dispatch('postMethod', { method }).then(response => {
        if (response.error == '0') {
          toSipAdetiRec.value = response.response.toSipAdetiRec
          toSipAdetiRec.value.forEach(item => {
            console.log(item.topSipAdeti)
            item.topSipAdeti = parseFloat(
              item.topSipAdeti.replace(',', '').replace('.', '').replace(',', '.'),
            ).toLocaleString('tr-TR')
            console.log(item.topSipAdeti)
          })
          sonuc.value = response.response.sonuc
          toplamSiparisTutari.value = response.response.toplamSiparisTutari
          thisMonthOrderCount.value = response.response.thisMonthOrderCount
          // currenci.value = response.response.balance.CURRENCY

          console.log(toSipAdetiRec.value)

          balance.value = response.response.balance
        } else {
          logoutUser()
        }
      })
    }

    return {
      userData,
      toSipAdetiRec,
      headers,
      sonuc,
      currenci,
      hasLoaded,
      toplamSiparisTutari,
      thisMonthOrderCount,
      balance,

      // icons
      icons: {
        mdiCartArrowDown,
        mdiPodium,
        mdiIp,
        mdiLogin,
        mdiStar,
        mdiTrayFull,
        mdiFilter,
        mdiSend,
        mdiCalendarClock,
        mdiCalendar,
        mdiChevronLeft,
        mdiAt,
        mdiPhone,
        mdiAccount,
        mdiCodeBrackets,
        mdiDomain,
      },
    }
  },
}
</script>

<style></style>
